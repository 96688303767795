/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.product-grid .plp-grid-content.plp-inline-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 50%;
      flex: 0 0 50%;
  max-width: 50%; }
  .product-grid .plp-grid-content.plp-inline-content .experience-component h4 {
    font-size: 1rem; }
    @media (min-width: 769px) {
      .product-grid .plp-grid-content.plp-inline-content .experience-component h4 {
        font-size: 1.375rem; } }
  .product-grid .plp-grid-content.plp-inline-content .experience-component p {
    color: #004987;
    font-size: 0.875rem; }
    @media (min-width: 769px) {
      .product-grid .plp-grid-content.plp-inline-content .experience-component p {
        font-size: 1rem; } }
  .product-grid .plp-grid-content.plp-inline-content::before {
    display: none; }
  .product-grid .plp-grid-content.plp-inline-content .experience-commerce_assets-plpContentTile {
    display: -ms-flexbox;
    display: flex;
    width: 100%; }
    @media (min-width: 769px) {
      .product-grid .plp-grid-content.plp-inline-content .experience-commerce_assets-plpContentTile {
        width: 100%; } }
  @media (min-width: 769px) {
    .product-grid .plp-grid-content.plp-inline-content {
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
      max-width: 33.33333%; } }
  .product-grid .plp-grid-content.plp-inline-content .content-tile-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-direction: column;
        flex-direction: column;
    border: 10px solid transparent;
    border-style: inset;
    position: relative;
    width: 100%; }
    @media (min-width: 769px) {
      .product-grid .plp-grid-content.plp-inline-content .content-tile-container {
        width: 100%; } }
    .product-grid .plp-grid-content.plp-inline-content .content-tile-container .btn {
      padding: 0.75rem 0.5rem;
      color: #004987; }
  .product-grid .plp-grid-content.plp-inline-content .inline-content-tile-text {
    border: 20px solid transparent;
    width: 100%;
    position: absolute;
    z-index: 1; }
    .product-grid .plp-grid-content.plp-inline-content .inline-content-tile-text .content-tile-link {
      -ms-flex-pack: center;
          justify-content: center; }
      @media (min-width: 769px) {
        .product-grid .plp-grid-content.plp-inline-content .inline-content-tile-text .content-tile-link {
          -ms-flex-pack: left;
              justify-content: left; } }
    @media (max-width: 768.98px) {
      .product-grid .plp-grid-content.plp-inline-content .inline-content-tile-text {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center; } }
    @media (min-width: 769px) {
      .product-grid .plp-grid-content.plp-inline-content .inline-content-tile-text {
        border: unset;
        text-align: left;
        -ms-flex-pack: left;
            justify-content: left; }
        .product-grid .plp-grid-content.plp-inline-content .inline-content-tile-text.left {
          padding-left: 10%; }
        .product-grid .plp-grid-content.plp-inline-content .inline-content-tile-text.right {
          padding-right: 10%; } }
    @media (min-width: 1024px) {
      .product-grid .plp-grid-content.plp-inline-content .inline-content-tile-text.left {
        padding-left: 15%; }
      .product-grid .plp-grid-content.plp-inline-content .inline-content-tile-text.right {
        padding-right: 15%; } }
  .product-grid .plp-grid-content.plp-inline-content .content-tile-cta-container {
    -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    padding: 0 0 1rem;
    margin: unset; }
  .product-grid .plp-grid-content.plp-inline-content .content-tile-figure {
    position: relative;
    max-width: 100%; }
    .product-grid .plp-grid-content.plp-inline-content .content-tile-figure .image-link {
      width: 100%; }
    .product-grid .plp-grid-content.plp-inline-content .content-tile-figure picture {
      width: 100%; }
    .product-grid .plp-grid-content.plp-inline-content .content-tile-figure .content-tile-image {
      width: 100%;
      height: auto; }

.page-redesign .product-grid .plp-grid-content.plp-inline-content .content-tile-container .btn.btn-outline-primary {
  color: #001E62; }
  .page-redesign .product-grid .plp-grid-content.plp-inline-content .content-tile-container .btn.btn-outline-primary:hover {
    color: #fff; }
